.priceBreakDownBox {
  display: flex;
  align-items: center;
  color: black;
  font-size: 18px;
}

.priceBreakDownTitle {
  flex-grow: 1;
}

.priceBreakDownTitle {
  flex-grow: 1;
}

.priceBreakDownContainer {
  width: 100%;
}

.priceBreakDownBox:last-of-type {
  margin-top: 15px;
}
